import axios from 'axios'
import config from '../config.json'
import { PATH } from '../../src/data/constants/paths'
import { msalInstance } from './msal'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { processJsonData } from '../utils/convert-timezones.helper'

export const apiClient = axios.create({
  baseURL: config.backend,
})

const apiScopes = process.env.REACT_APP_AUTH_APISCOPES?.split(',') || ['']

apiClient.interceptors.request.use(
  async (config) => {
    try {
      await msalInstance.initialize()
      const accounts = msalInstance.getAllAccounts()
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: apiScopes,
          account: accounts[0],
        }

        const authResult = await msalInstance.acquireTokenSilent(accessTokenRequest)
        if (authResult && authResult.accessToken) {
          config.headers.Authorization = `Bearer ${authResult.accessToken}`
        }
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect({
          scopes: apiScopes,
        })
      } else {
        console.error('Failed to acquire access token', error)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = processJsonData(response.data)
    }
    return response
  },
  (error) => {
    if (error.response?.status === 403) {
      window.location.replace(PATH.FORBIDDEN)
    }
    return Promise.reject(error)
  },
)
