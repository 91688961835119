import { atom, createStore } from 'jotai'
import { User } from '../models/user'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { downtimeCategories } from './constants/tags'
import { getTimeAtTZ } from '../utils/time-set.helper'
import { MutableRefObject } from 'react'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

dayjs.extend(utc)
dayjs.extend(timezone)
export const tz = 'Australia/Perth' //Note: has to be dynamic if sites are from different time zones in the future.

export const store = createStore()
export const currentUserAtom = atom<User>({
  email: '',
  id: '',
  name: '',
  roles: [],
})

export const commentAtom = atom<boolean>(false)

let currentDateBrowser = dayjs()
const currentDateAtTZ = getTimeAtTZ(tz, currentDateBrowser)

export const siteTimeZone = atom<string>(tz)
export const todaysDate = atom<dayjs.Dayjs>(currentDateAtTZ)

export const globalDates = atom<DateRange<dayjs.Dayjs>>([
  currentDateAtTZ.subtract(6, 'day').endOf('day'),
  currentDateAtTZ,
])

export const globalDowntimeTag = atom<string>(downtimeCategories.all)
export const globalDowntimeReason = atom<string>('')
export const globalTags = atom<string>('')
export const globalUpdateDowntimeId = atom<string>('')

export const globalDeleteSuccessState = atom<boolean>(false)
export const globalDeleteErrorState = atom<boolean>(false)
export const globalPostSuccessState = atom<boolean>(false)
export const globalPostErrorState = atom<boolean>(false)

export const globalGridApiRef = atom<MutableRefObject<GridApiPro> | null>(null)

export const globalModalState = atom<boolean>(false)
