import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const TIMEZONE = 'Australia/Perth'

export const convertToTimezone = (utcDateStr: string | number | dayjs.Dayjs | Date | null | undefined) => {
  return dayjs.utc(utcDateStr).tz(TIMEZONE).format('YYYY-MM-DD HH:mm:ss')
}

const isUtcDateString = (value: unknown) => {
  return typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/.test(value)
}

const convertDatesInObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(convertDatesInObject)
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
      acc[key] = isUtcDateString(value) ? convertToTimezone(value as string) : convertDatesInObject(value)
      return acc
    }, {})
  }
  return obj
}

export const processJsonData = (data: any) => convertDatesInObject(data)
